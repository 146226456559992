#logInContainer{
    width:100vw;
    margin:auto;

    min-height: 100vh;
    background-color: black ;
    margin:auto;
    flex:1;
    display: grid;
    font-family: 'Futura-Medium' ;
    justify-content: center;
    align-items: center;
}

#logoImgRed{
    width:35vh;
    margin-top: 3vh;
    margin-bottom: 3vh;
}


