

#main{
    background-color: black;
    min-height: 100vh;
    min-width: 100vw;
    color:white;
    margin:auto;
    flex:1;
    display: grid;
    font-family: 'Futura-Light' ;
    justify-content: center;
    align-items: center;
}

#goodparkImg{
    width:50vh;
    margin-bottom: 10vh;
}

#white{
    color:white!important;
}

a:-webkit-any-link{
    text-decoration-line: none;
    color:white;
}


